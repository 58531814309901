<template>
  <base-container>
    <template v-slot:header>
      <v-btn class="mx-2" @click="onBack" color="accent" :disabled="loading"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        @click="validate"
        :disabled="!valid || loading || !editable"
        color="accent"
        >Salvar</v-btn
      >
    </template>
    <v-card :loading="loading" :disabled="loading">
      <v-tabs v-model="tab" background-color="white" color="accent" height="52">
        <v-tab>Dados</v-tab>
        <v-tab v-if="doc.role && doc.role == 'Cliente'">
          <v-badge
            :value="animals && animals.length"
            color="accent"
            dot="dot"
            >Animais</v-badge
          >
        </v-tab>
        <v-spacer></v-spacer>
        <transition appear="appear" name="bounce" mode="out-in">
          <v-btn
            class="mt-2 mr-4 elevation-2"
            @click="onFloatBtnPress('edit')"
            color="deep-orange lighten-1"
            dark="dark"
            fab="fab"
            key="edit"
            small="small"
            v-if="!inserting && tab === 0"
          >
            <v-icon>{{ editable ? "mdi-close" : "mdi-pencil" }}</v-icon>
          </v-btn>
          <v-btn
            class="mt-2 mr-4 elevation-2"
            @click="onFloatBtnPress('add')"
            color="accent"
            dark="dark"
            fab="fab"
            key="add"
            small="small"
            v-else-if="tab === 1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </transition>
        <v-tab-item>
          <v-container fluid="fluid">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation="lazy-validation"
            >
              <v-card class="elevation-1">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        :rules="nameRules"
                        @keypress.enter="$refs.document.focus()"
                        label="Nome completo"
                        ref="name"
                        v-model="doc.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        :label="doc.document.length > 14 ? 'CNPJ' : 'CPF'"
                        :rules="documentRules"
                        @keypress.enter="$refs.role.focus()"
                        ref="document"
                        type="tel"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        v-model="doc.document"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-select
                        :disabled="!editable"
                        :items="$store.getters.accountRoles"
                        :rules="[v => !!v || 'Campo obrigarório']"
                        @keypress.enter="$refs.genre.focus()"
                        label="Função"
                        ref="role"
                        v-model="doc.role"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-select
                        :disabled="!editable"
                        :items="$store.getters.accountGenre"
                        @keypress.enter="$refs.email.focus()"
                        label="Gênero"
                        ref="genre"
                        v-model="doc.genre"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        :rules="emailRules"
                        @keypress.enter="$refs.phone.focus()"
                        label="E-mail"
                        ref="email"
                        type="email"
                        v-model="doc.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        @keypress.enter="$refs.zipcode.focus()"
                        label="Telefone"
                        ref="phone"
                        type="tel"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        v-model="doc.phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="elevation-1 mt-4" :loading="loadingAddress">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        :rules="zipcodeRules"
                        @blur="getZipcode"
                        @keypress.enter="getZipcode"
                        label="CEP"
                        ref="zipcode"
                        type="tel"
                        v-mask="'#####-###'"
                        v-model="doc.address.zipcode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        :rules="[v => !!v || 'Campo obrigarório']"
                        @keypress.enter="$refs.state.focus()"
                        label="Município"
                        ref="city"
                        v-model="doc.address.city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-autocomplete
                        :disabled="!editable"
                        :filter="customFilter"
                        :items="stateList"
                        :rules="[v => !!v || 'Campo obrigarório']"
                        @keypress.enter="$refs.street.focus()"
                        item-text="name"
                        label="Estado"
                        no-data-text="Nenhum resultado"
                        ref="state"
                        return-object="return-object"
                        v-model="doc.address.state"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        @keypress.enter="$refs.number.focus()"
                        label="Logradouro"
                        ref="street"
                        v-model="doc.address.street"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        @keypress.enter="$refs.district.focus()"
                        label="Número"
                        ref="number"
                        v-model="doc.address.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        @keypress.enter="$refs.complement.focus()"
                        label="Bairro"
                        ref="district"
                        v-model="doc.address.district"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :disabled="!editable"
                        @keypress.enter="$refs.complement.blur()"
                        label="Complemento"
                        ref="complement"
                        v-model="doc.address.complement"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid="fluid">
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items-per-page="5"
              :items="animals"
              :loading="loadingAnimals"
              disable-filtering="disable-filtering"
              disable-pagination="disable-pagination"
              disable-sort="disable-sort"
              hide-default-footer="hide-default-footer"
              hide-default-header="hide-default-header"
            >
              <template v-slot:[`item.name`]="{ item }"
                ><span class="body-1">{{ item.name }}</span></template
              >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn @click="deleteAnimalItem(item)" icon="icon" text="text">
                  <v-icon :size="22">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <collection-dialog
      :filters="animalsFilters"
      @data-selected="onSelectAnimals"
      title="Selecione um animal"
      type="animals"
      v-if="collectionDialog"
      v-model="dialog"
    ></collection-dialog>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer.vue";
import CollectionDialog from "@/components/CollectionDialog.vue";

import { mask } from "vue-the-mask";
import utils from "../../plugins/utils";
import getCep from "../../plugins/getCep";
import { db } from "../../plugins/google/firebase";
import $store from "@/store";

const { COLLECTION_ACCOUNTS, COLLECTION_ANIMALS } = $store.getters,
  SOURCE = { source: "cache" };

export default {
  name: "account-edit",
  components: { BaseContainer, CollectionDialog },
  directives: { mask },
  props: ["type"],

  data: () => ({
    collectionDialog: false,
    dialog: false,
    inserting: true,
    animals: [],

    docRef: "",
    editable: false,
    loading: false,
    loadingAddress: false,
    loadingAnimals: false,
    tab: 0,
    valid: true,

    doc: {
      address: {
        complement: "",
        coords: { latitude: 0, longitude: 0 },
        city: "",
        district: "",
        number: "",
        state: { initials: "", name: "" },
        street: "",
        zipcode: "",
      },
      document: "",
      email: "",
      genre: "",
      name: "",
      phone: "",
      role: "",
    },

    nameRules: [
      (v) => !!v || "Campo obrigarório",
      (v) => (v && v.length > 2) || "Mínimo 3 caracteres",
    ],
    documentRules: [],
    emailRules: [(v) => utils.validateEmail(v) || "E-mail deve ser válido"],
    zipcodeRules: [
      (v) => !!v || "Campo obrigarório",
      (v) => (v && v.length > 8) || "CEP deve ser válido",
    ],
    stateList: utils.ufs,

    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Ações", align: "end", value: "action", sortable: false },
    ],

    accountId: null,

    animalsFilters: {
      owner: true,
    },
  }),

  created() {
    this.inserting = this.type === "add";
  },

  mounted() {
    this.documentRules = [
      (v) => !!v || "Campo obrigarório",
      (v) =>
        utils.validateDocs(v, this.doc.document.length > 14 ? "J" : "F") ||
        "Documento inválido",
    ];

    this.onStart();
  },

  watch: {
    tab() {
      requestAnimationFrame(() => {
        this.editable && !this.inserting && (this.editable = false);
      });
    },
  },

  methods: {
    onStart() {
      if (this.$route.params.docRef) {
        this.getDataFromApi(this.$route.params.docRef);
        this.onAnimalsSnapshot();
      } else {
        this.docRef = db.collection(COLLECTION_ACCOUNTS).doc();
        this.editable = true;
        setTimeout(() => {
          this.$refs.name.focus();
        }, 250);
      }
    },

    customFilter(item, queryText) {
      const one = item.name.toLowerCase();
      const two = item.initials.toLowerCase();
      const searchText = queryText.toLowerCase();

      return one.indexOf(searchText) > -1 || two.indexOf(searchText) > -1;
    },

    deleteAnimalItem(item) {
      this.$dialog.show(
        "Remover animal",
        `"${item.name}" está prestes a ser removido deste cliente. Deseja continuar?`,
        [
          { text: "cancelar" },
          {
            text: "remover",
            style: "destrutive",
            onClick: () => {
              this.loadingAnimals = true;
              db.collection(COLLECTION_ANIMALS)
                .doc(item.id)
                .update({
                  owner: null,
                })
                .then(this.onAnimalsSnapshot)
                .catch(() =>{
                  this.$snackbar.show({ title: "Não foi possível remover" });
                })
                .finally(() => (this.loadingAnimals = false));
            },
          },
        ],
      );
    },

    getZipcode() {
      if (this.loadingAddress) {
        return;
      }
      this.loadingAddress = true;
      getCep(utils.cepMask(this.doc.address.zipcode, true))
        .then((d) => {
          if (d && d.cep) {
            d.logradouro && (this.doc.address.street = d.logradouro);
            d.complemento && (this.doc.address.complement = d.complemento);
            d.bairro && (this.doc.address.district = d.bairro);
            if (d.municipio) {
              this.doc.address.city = d.municipio.nome;
              if (d.municipio.uf) {
                this.doc.address.state = {
                  initials: d.municipio.uf.sigla,
                  name: d.municipio.uf.nome,
                };
              }
            }
          }
        })
        .catch(() => null)
        .finally(() => {
          this.loadingAddress = false;
          setTimeout(() => this.$refs.city.focus(), 250);
        });
    },

    getDataFromApi(docRef) {
      this.docRef = db.collection(COLLECTION_ACCOUNTS).doc(docRef);
      this.accountId = this.docRef.id;

      this.docRef
        .get(SOURCE)
        .then((snapshot) => {
          const newDoc = snapshot.data();
          newDoc.document = utils.docMask(newDoc.document);
          this.doc = newDoc;
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    getStateData() {
      return {
        address: this.doc.address,
        document: utils.docMask(this.doc.document, true),
        email: this.doc.email,
        genre: this.doc.genre,
        name: this.doc.name,
        phone: utils.phoneMask(this.doc.phone, true),
        role: this.doc.role,
      };
    },

    onAnimalsPress() {
      if (!this.$refs.form.validate()) {
        return;
      }
    },

    onAnimalsSnapshot() {
      if (!this.docRef) {
        return;
      }
      db.collection(COLLECTION_ANIMALS)
        .where("owner.id", "==", this.docRef.id)
        .get(SOURCE)
        .then((snap) => {
          let items = [];
          snap.forEach((doc) => {
            items.push({ id: doc.id, name: doc.get("name") });
          });
          this.animals = items;
        });
    },

    onBack() {
      this.$router.go(-1);
    },

    async saveToFirestore() {
      try {
        const isValid = await this.validateData();
        if (!isValid) {
          return;
        }

        const doc = this.getStateData();
        doc.updatedAt = new Date().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        this.docRef.set(doc, { merge: true });
        this.accountId = this.docRef.id;
        this.editable = false;
        this.inserting = false;
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
      } catch {
        this.$snackbar.show({ title: "Erro ao salvar dados, sem conexão" });
      }
    },

    onSelectAnimals(data) {
      if (!data[0]) {
        return;
      }
      this.loadingAnimals = true;
      const doc = data[0];
      db.collection(COLLECTION_ANIMALS)
        .doc(doc.id)
        .update({
          owner: { id: this.docRef.id, name: this.doc.name },
        })
        .then(this.onAnimalsSnapshot)
        .catch(() => {
          this.$snackbar.show({ title: "Algo deu errado ao adicionar" });
        })
        .finally(() => (this.loadingAnimals = false));
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.saveToFirestore();
      }
    },

    async validateData() {
      const { document, email } = this.doc;
      const docRef = db.collection(COLLECTION_ACCOUNTS),
        docQuery = docRef.where(
          "document",
          "==",
          utils.docMask(document, true),
        ),
        emailQuery = docRef.where("email", "==", email);

      const docSnapshot = await docQuery.get(SOURCE);
      const emailSnapshot = await emailQuery.get(SOURCE);
      let docCount = false,
        emailCount = false;

      docSnapshot.forEach((val) => {
        if (val.id !== this.docRef.id) {
          docCount = true;
        }
      });
      emailSnapshot.forEach((val) => {
        if (val.id !== this.docRef.id) {
          emailCount = true;
        }
      });

      if (docCount) {
        this.$snackbar.show({ title: "Documento informado já existe" });
        return false;
      }
      if (emailCount) {
        this.$snackbar.show({ title: "E-mail informado já existe" });
        return false;
      }
      return true;
    },

    onFloatBtnPress(key) {
      switch (key) {
        case "edit":
          this.editable = !this.editable;
          break;
        case "add":
          if (!this.accountId) {
            this.tab = 0;
            return;
          }
          if (!this.collectionDialog) {
            this.collectionDialog = true;
          }
          this.$nextTick(() => {
            setTimeout(() => {
              this.dialog = true;
            }, 250);
          });
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
